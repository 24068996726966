.rc-time-picker-panel {
  z-index: 9999 !important;
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
}

.rc-time-picker {
  width: 100%;
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  margin-top: 8px;
  margin-bottom: 4px;
}

.rc-time-picker .rc-time-picker-input {
  padding: 10.5px 14px;
  font-size: 1rem;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  border-width: 1px;
  line-height: 1;
  height: auto;
  color: #111;
}

.rc-time-picker .rc-time-picker-clear {
  top: 8px;
  right: 10px;
}
